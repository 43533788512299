// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
global.Rails = Rails;
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

require("underscore/underscore-min")
require("mustache/mustache.min")
require("moment/moment")
require("clndr/clndr.min")
require("packs/jquery-address")
require("jstree/dist/jstree")
require("timeline/dist/js/timeline.min")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

// https://github.com/RobinHerbots/Inputmask
require("inputmask/dist/jquery.inputmask.min");
require("inputmask/dist/bindings/inputmask.binding");

require("chartkick")
require("chart.js")

import "proposals"
