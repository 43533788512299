window.proposalList = {
  handler: {}
};

proposalList.ready = function() {
  var handler;
  var customerTable;
  
  handler = {
    initSelectAll: function() {
      $('.proposal-selector.selector').on('change', function() {
        console.log("Checked? : " + $(this).parent().checkbox("is checked"))
        if ($(this).parent().checkbox("is checked")) {
          $('.proposal.checkbox').checkbox("set checked")
          $('.delete-selected.button').show();
          $('.set-current.button').hide();
        } else {
          $('.proposal.checkbox').checkbox("set unchecked")
          $('.delete-selected.button').hide();
          $('.set-current.button').hide();
        }
      })
      
      $('.delete-selected.button').hide();
      handler.toggleActionButtons();
    },
    toggleActionButtons: function() {
      var arrayCheckboxes = $('.proposal.checkbox').checkbox('is checked')
      var checkedCheckboxes = []
      for (var i = 0; i < arrayCheckboxes.length; i++) {
        if (arrayCheckboxes[i] == true) {
          $('.delete-selected.button').show()
          checkedCheckboxes.push(i)
        }
      }
      if (checkedCheckboxes.length == 0) {
        $('.delete-selected.button').hide()
      }
      if (checkedCheckboxes.length == 1) {
        $('.set-current.button').show();
      } else {
        $('.set-current.button').hide();
      }
    },
    initProposalSelector: function() {
      $('.ui.checkbox').checkbox(); 
      $('.current_proposal.selector').on('change', function() {
        handler.toggleActionButtons()
      })
    },
    init: function() {
      // Fix menu when passed
      $('.masthead').visibility({
        once: false,
        onBottomPassed: function() {
          $('.fixed.menu').transition('fade in');
        },
        onBottomPassedReverse: function() {
          $('.fixed.menu').transition('fade out');
        }
      });

      // create sidebar
      $('.ui.sidebar.main.menu').sidebar('attach events', '.toc.item');
      $('.ui.sidebar.search.menu').sidebar('attach events', '.search.item');
        
      $('.ui.dropdown').dropdown();      
           
      
      $('.delete-selected.button').on('click', function() {
        var arrayCheckboxes = $(".proposal.checkbox")
        var arrayOfProposalIds = [];
        for (var i = 0; i < arrayCheckboxes.length; i++) {
          if ($(arrayCheckboxes[i]).checkbox("is checked")) {
            arrayOfProposalIds.push( $(arrayCheckboxes[i]).data("proposal-id") )
          }
        }
        console.log("Array of proposal ids: " + arrayOfProposalIds)
        console.log("String: " + arrayOfProposalIds.join())
        $(this).api({
          on: 'now',
          url: '/proposals/remove_selected',
          method: 'DELETE',
          dataType: 'script',
          data: {
            proposal_ids: arrayOfProposalIds.join()
          }
        })
      })
      
      $('.set-current.button').on('click', function() {
        var arrayCheckboxes = $(".proposal.checkbox")
        var arrayOfProposalIds = [];
        for (var i = 0; i < arrayCheckboxes.length; i++) {
          if ($(arrayCheckboxes[i]).checkbox("is checked")) {
            arrayOfProposalIds.push( $(arrayCheckboxes[i]).data("proposal-id") )
          }
        }
        if (arrayOfProposalIds.length == 1) {
          $('.ui.dimmer').addClass("active");
          $(this).api({
            on: 'now',
            url: '/proposals/set_current',
            method: 'POST',
            dataType: 'script',            
            data: {
              id: arrayOfProposalIds[0]
            },
            onComplete: function(respoose, element, xhr) {
              $('.ui.dimmer').removeClass("active")
            }
          })
        } else {
          alert("You cannot select more than 1 proposal.")
        }
      });
      
      handler.initSelectAll();  
      handler.initProposalSelector();
    }
  }
  
  proposalList.handler = handler;
  handler.init();
}
